@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Robot Crush;
  src: url("./assets/RobotCrush.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

.title {
  font-family: "Robot Crush";
}
